<template>
  <div class="container">
    <div class="row">
      <div class="col-sm">
        <List
          type="alarm"
          :listItem="getAlarms"
          :simulatedEvents="getSimulatedEvents"
          :messages="getAlarmMessages"
          :handleClick="getAlertDetails"
          :title="$t('alarms.active')"
          titleColor="red-bg"
          right_icon="fa-bath"
          left_icon="fa-user-alt"
          metaField="metadata"
          subField="roomCode"
          :emptyMsg="$t('alarms.emptyActive')"
        />
      </div>
      <div class="col-sm">
        <List
          type="warning"
          :listItem="getWarnings"
          :messages="getWarningMessages"
          :handleClick="getAlertDetails"
          :title="$t('alarms.offline')"
          titleColor="yellow-bg"
          right_icon="fa-bath"
          left_icon="fa-user-alt"
          metaField="metadata"
          subField="roomCode"
          :emptyMsg="$t('alarms.emptyOffline')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import List from '@/components/list/List.vue'
import { isEmpty } from 'lodash'
export default {
  name: 'Alarms',
  components: {
    List
  },
  computed: {
    ...mapGetters({
      getAlarms: 'devices/getAlarms',
      getAlarmMessages: 'devices/getAlarmMessages',
      getWarnings: 'devices/getWarnings',
      getWarningMessages: 'devices/getWarningMessages',
      getDeviceInfo: 'devices/getDeviceInfo',
      getRootContainer: 'containers/getRootContainer',
      getCurrentCustomer: 'login/getCurrentCustomer',
      getCurrentFulfiller: 'login/getCurrentFulfiller',
      getSimulatedEvents: 'devices/getSimulatedEvents'
    })
  },
  methods: {
    ...mapActions({
      setSelectedContainer: 'containers/setSelectedContainer',
      fetchRegisteredDevices: 'devices/fetchRegisteredDevices',
      fetchContainers: 'containers/fetchContainers',
      fetchDevices: 'devices/fetchDevices'
    }),
    getAlertDetails (id) { id && this.setSelectedContainer(id) }
  },
  async mounted () {
    await this.fetchDevices()
    await this.fetchContainers()
    if (isEmpty(this.getDeviceInfo)) {
      // await this.fetchRegisteredDevices()
    }
  },
  watch: {
    async getCurrentCustomer (newVal) {
      await this.fetchDevices()
      await this.fetchContainers()
      await this.fetchRegisteredDevices()
    },
    async getRootContainer (newVal) {
      console.log('new root container', newVal)
      await this.fetchDevices()
      await this.fetchContainers()
      await this.fetchRegisteredDevices()
    },
    async getCurrentFulfiller (newVal) {
      // await this.fetchContainers()
    }
  }
}
</script>
