<template>
  <div>
    <v-btn
      color="primary"
      elevation="2"
      class="ma-3"
      small
      :disabled="isDisabled"
      @click="handleClick"
    >
      Simulate smart insight
    </v-btn>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { postDemoSmartEvent } from '../../api/devices/index'
export default {
  name: 'SmartSimBtn',
  computed: {
    ...mapGetters({
      myInfo: 'login/myInfo',
      getCurrentFulfiller: 'login/getCurrentFulfiller',
      getCurrentCustomer: 'login/getCurrentCustomer',
      getRegisteredDevices: 'devices/getRegisteredDevices'
    }),
    isDisabled: function () {
      return !(
        this.customerAdmin &&
        this.getCurrentFulfiller &&
        this.getCurrentCustomer &&
        this.getRegisteredDevices.length > 0
      )
    },
    customerAdmin: function () {
      return this.myInfo?.roles?.some(role => ['CUSTOMER_ADMIN'].includes(role))
    }
  },
  methods: {
    handleClick: async function () {
      const devices = this.getRegisteredDevices.map(d => d.device)
      const deviceId = devices[Math.floor(Math.random() * devices.length)].vendorDeviceId
      await postDemoSmartEvent(deviceId)
    }
  }
}
</script>
