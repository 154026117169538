<template>
  <div>
    <div class="row">
      <div class="col-sm-4">
        <span>({{item.type}} - simulated) {{item.roomName}}</span>
        <br/>
        <span>{{item.description}}</span>
      </div>

      <div class="col-sm-4" style="padding: 0; display: flex; justify-content: center; align-items: center;">
        <Icon
          v-if="item.statusSubtype === 'fallReported'"
          :iconName="roomTypeIcon"
          size="10px"
          style="margin-right: 25px;"
          color="grey"
        />
        <ResidentIcon
          v-if="item.statusSubtype === 'fallDetected' || item.statusSubtype === 'fallReported'"
          residentFall="true"
          size="45px"
          containsDescription="true" />
        <Icon
          v-else
          :iconName="smartInsightIcon"
          size="10px"
          color="grey"
        />
      </div>
      <div class="col-sm-4">
        <span>{{item.startTimestamp}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/elements/Icon'
import ResidentIcon from '@/components/elements/ResidentIcon'
import { iconList } from '../../assets/icons/iconList'
export default {
  name: 'NewListItem',
  components: {
    Icon,
    ResidentIcon
  },
  props: {
    item: Object
  },
  computed: {
    smartInsightIcon () {
      return iconList.alerts[this.item?.statusSubtype]?.icon || 'question'
    },
    roomTypeIcon () {
      return iconList.roomType[this.item?.roomType]?.icon || 'couch'
    }
  }
}
</script>
