<template>
  <div class="list-group">
    <v-card class="column-container">
      <div class="top-sticky">
        <div class="list-group-item">
          <h3 class="text-left">{{ title }}</h3>
        </div>
        <div
          :class="[
            'list-group-item',
            'list-title',
            titleColor ? titleColor : 'no-bg'
          ]"
        >
          {{ listCount }}
        </div>
      </div>
      <div
        v-for="item in sortedActiveAlarms"
        v-bind:key="!item.statusSubtype? item.container.id : item.id"
        class="list-group-item list-group-item-action flex-column align-items-start hover-pointer"
        @click="handleClick(enableClick(item))"
      >
        <ListItem
          v-if="item.container"
          :item="item"
          :message="getDeviceMsg(item)"
          :type="type"
          :metaField="metaField"
          :subField="subField"
          :left_icon="left_icon"
          :location="getLocation(item)"
        />
        <NewListItem
          v-else
          :item="item"
        />
      </div>
      <h3 v-if="isList">
        {{ emptyMsg }}
      </h3>
    </v-card>
  </div>
</template>

<script>
import ListItem from '@/components/list/ListItem'
import NewListItem from '@/components/list/NewListItem'
import { mapGetters } from 'vuex'
import { combineLocation } from '@/utils/nameArrayShortener'
import { formatISOStringAsFullDate } from '@/utils/timeFunctions'
export default {
  name: 'List',
  components: {
    ListItem,
    NewListItem
  },
  props: {
    type: String,
    title: String,
    listItem: Array,
    messages: Array,
    handleClick: Function,
    titleColor: String,
    right_icon: String,
    left_icon: String,
    metaField: String,
    subField: String,
    emptyMsg: String,
    simulatedEvents: Array
  },
  data () {
    return {
      activeAlarms: []
    }
  },
  methods: {
    getLocation (item) {
      let name = combineLocation(item.container?.fullyQualifiedName, ', ', 18)
      return name || 'Device'
    },
    isDeviceContainer (container) {
      return !!container.renderingMetadata?.deviceId
    },
    getDeviceMsg (item) {
      return this.messages?.find(msg => item.device?.id === msg.data?.device?.id)
    },
    sortAlarmsByTime () {
      if (this.simulatedEvents) {
        this.activeAlarms = this.listItem.concat(this.simulatedEvents)
        this.activeAlarms = this.activeAlarms.sort((a, b) => new Date(b.startTimestamp) - new Date(a.startTimestamp))
        return
      }
      this.activeAlarms = this.listItem
    },
    enableClick (item) {
      if (item.container) {
        const id = this.isDeviceContainer(item.container) ? item.container.parentId : item.container.id
        return id
      }
      return null
    }
  },
  mounted () {
    this.sortAlarmsByTime()
  },
  computed: {
    ...mapGetters({
      getContainerById: 'containers/getContainerById'
    }),
    listCount: function () {
      return this.simulatedEvents ? (this.listItem?.length + this.simulatedEvents?.length) : this.listItem?.length
    },
    isList: function () {
      return (this.listItem?.length === 0 && this.simulatedEvents?.length === 0)
    },
    sortedActiveAlarms: function () {
      if (this.type === 'alarm') return this.activeAlarms
      else {
        if (this.activeAlarms === []) return []
        try {
          const collator = new Intl.Collator(undefined, {
            numeric: true,
            sensitivity: 'base'
          })
          const alarms = this.activeAlarms
          alarms.sort(function (b, a) {
            return collator.compare(a.device?.status?.WIFI?.instant, b.device?.status?.WIFI?.instant)
          })
          return alarms
        } catch (err) {
          console.log('Alarms sort error: ', err)
        }
      }
      return []
    }
  },
  watch: {
    async listItem (newValue, oldValue) {
      if (this.type === 'alarm') {
        for (let i = 0; i < this.listItem.length; i++) {
          let timestamp = this.getDeviceMsg(this.listItem[i]).meta?.instant || this.listItem[i].device?.status?.FALL?.instant
          timestamp = formatISOStringAsFullDate(timestamp)
          this.listItem[i].startTimestamp = timestamp
        }
      }
      this.sortAlarmsByTime()
    },
    async simulatedEvents (newValue, oldValue) {
      this.sortAlarmsByTime()
    }
  }
}
</script>
