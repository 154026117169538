<template>
  <div>
    <div class="d-flex w-100 justify-content-between">
      <h5 class="mb-1">{{itemTitle}}</h5>
    </div>
    <div class="row">
      <div class="col-sm-4" :if="location">
        <span>{{location}}</span>
      </div>

      <div class="col-sm-4" style="padding: 0; display: flex; justify-content: center; align-items: center;" v-if="item">
        <Icon
          v-if="item && item.container.renderingMetadata && item.container.renderingMetadata.iconName"
          :iconName="getDeviceIcon"
          size="10px"
          style="margin-right: 25px;"
          color="grey"
        />
        <ResidentIcon
          :residentFall="getStatus(item).fall"
          size="45px"
          :containsDescription="true" />
      </div>
      <div class="col-sm-4" :if="timestamp">
        <span>{{timestamp}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { formatISOStringAsFullDate } from '@/utils/timeFunctions'
import ResidentIcon from '@/components/elements/ResidentIcon'
import Icon from '@/components/elements/Icon'
import { iconList } from '../../assets/icons/iconList'
export default {
  name: 'ListItem',
  components: {
    ResidentIcon,
    Icon
  },
  props: {
    item: Object,
    type: String,
    left_icon: String,
    metaField: String,
    subField: String,
    location: String,
    message: Object
    // meta & sub should be the object structure fed into the list
  },
  data () {
    return {
      timestamp: null
    }
  },
  computed: {
    itemTitle () {
      const { metaField, subField, item } = this
      try {
        return subField ? item[`${metaField}`][`${subField}`] : item[`${metaField}`]
      } catch {
        return null
      }
    },
    getDeviceIcon () {
      return iconList[this.item?.container?.renderingMetadata?.iconName].icon
    }
  },
  methods: {
    getStatus (item) {
      const statusObj = {
        presence: item.device?.status?.PRESENCE?.presence,
        fall: item.status.isFall
      }
      if (this.type === 'warning') {
        const timestamp = this.message?.meta?.instant || item?.device?.status?.WIFI?.instant
        this.timestamp = formatISOStringAsFullDate(timestamp)
      } else if (this.type === 'alarm') {
        this.timestamp = item?.startTimestamp
      }
      return statusObj
    }
  }
}
</script>
