<template>
  <div class="Alarms">
    <div class="d-flex">
      <div v-if="superAdmin || customerAdmin" id="placeholder"></div>
      <h1 class="flex-1 text-center">Alarm Page</h1>
      <FallSimBtn v-if="customerAdmin"/>
      <SmartSimBtn v-if="customerAdmin"/>
    </div>
    <Alarms />
  </div>
</template>
<script>
import Alarms from '@/components/alarms/Alarms'
import FallSimBtn from '@/components/alarms/FallSimBtn'
import SmartSimBtn from '@/components/alarms/SmartSimBtn'
import { mapGetters } from 'vuex'
export default {
  components: {
    Alarms,
    FallSimBtn,
    SmartSimBtn
  },
  computed: {
    ...mapGetters({
      myInfo: 'login/myInfo'
    }),
    customerAdmin: function () {
      return this.myInfo?.roles?.some(role => ['CUSTOMER_ADMIN'].includes(role))
    },
    superAdmin: function () {
      return this.myInfo?.roles?.some(role => ['SUPERADMIN'].includes(role))
    }
  }
}
</script>

<style scoped>
#placeholder {
  /* Used to off set spacing right spacing of FallSimBtn */
  width: 151.469px
}

.flex-1 {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0%;
}
</style>
