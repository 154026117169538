<template>
  <div>
    <v-btn
      color="primary"
      elevation="2"
      class="ma-3"
      small
      :disabled="isDisabled"
      @click="handleClick"
    >
      Simulate fall alert
    </v-btn>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'FallSimBtn',
  computed: {
    ...mapGetters({
      myInfo: 'login/myInfo',
      getCurrentFulfiller: 'login/getCurrentFulfiller',
      getCurrentCustomer: 'login/getCurrentCustomer',
      getRegisteredDevices: 'devices/getRegisteredDevices'
    }),
    isDisabled: function () {
      return !(this.customerAdmin && this.getCurrentFulfiller && this.getCurrentCustomer && this.getRegisteredDevices.length > 0)
    },
    customerAdmin: function () {
      return this.myInfo?.roles?.some(role => ['CUSTOMER_ADMIN'].includes(role))
    }
  },
  methods: {
    ...mapActions({
      simulateFall: 'devices/simulateFall'
    }),
    handleClick: async function () {
      const data = { fulfillerId: this.getCurrentFulfiller[0], customerId: this.getCurrentCustomer[0] }
      await this.simulateFall(data)
    }
  }
}
</script>
